import { useCallback, useState, useMemo } from "react";

export function useTableData(table) {
  const tableData = useMemo(() => {
    let tData = {
      extraTableContainerClasses: table?.extraTableContainerClasses,
      extraTableContainerCSSModuleClasses: table?.extraTableContainerCSSModuleClasses,
      variant: table?.variant,
      data: table?.data
    };

    if (Array.isArray(table)) tData.data = table;
    return tData;
  }, [table]);

  return tableData;
}
