import React, { useRef } from "react";
import PropTypes from "prop-types";
import Typography from "components/Typography";
import { usePipeAnimation }from "hooks/usePipeAnimation";
import { setClasses } from "components/TableInfo/utils";
import classes from "../TableInfo.module.css";

const TableInfoNavRows = (props) => {
  const {
    updateTableData,
    navData,
    navData: { id: rowId = 'nav', cols = [], extraRowClasses = [] }
  } = props;

  const navRowRef = useRef(null);
  const updatePipeTab = usePipeAnimation(navRowRef, 'colText' );

  const columns = cols.map(({ content, id, extraColClasses = [] }, index) => (
    <th
      className={`${classes.col} ${setClasses(extraColClasses, classes)}`}
      key={id}
    >
      <div className={classes.colContent} >
        <Typography
          variant="body"
          size="l"
          weight="bold"
          className={`${classes.colText} colText`}
          onClick={() => {
            updateTableData && updateTableData(index, { navData, col: cols[index] });
            updatePipeTab(index);
          }}
        >
          {content}
        </Typography>
      </div>
    </th>
  ));

  return (
    <tr
      className={`${classes.row} ${classes.navRow} ${setClasses(extraRowClasses, classes)}`}
      key={rowId}
      ref={navRowRef}
    >
      {columns}
    </tr>
  );
};
TableInfoNavRows.propTypes = {
  id: PropTypes.string,
  updateMainData: PropTypes.func,
  navData: PropTypes.shape({
    cb: PropTypes.func,
    extraRowClasses: PropTypes.arrayOf(PropTypes.string),
    cols: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
        extraColClasses: PropTypes.arrayOf(PropTypes.string)
      })
    ).isRequired
  })
};

export default TableInfoNavRows;
