import Typography from "components/Typography";
import { NotesDataType }from "components/TableInfo/PropTypes";
import { setClasses } from "components/TableInfo/utils";
import classes from "./TableInfoNotes.module.css";
export function TableInfoNotes({ notesData = [] }) {
  if (!notesData?.length) return null;

  const notes = notesData.map(({ content, id, extraRowClasses = [] }) => (
    <div
      className={`
        ${classes.notesRow} 
        ${setClasses(extraRowClasses, classes)}
      `}
      key={id}
    >
      <Typography
        variant="body"
        size="s"
        className={classes.notesContent}
      >
        {content}
      </Typography>
    </div>
  ));
  return <div className={classes.notes}>{notes}</div>;
}

TableInfoNotes.propTypes = {
  notesData: NotesDataType
};
