import PropTypes from "prop-types";
import Typography from "components/Typography";
import Button from "components/Button";
import { setClasses } from "components/TableInfo/utils";
import classes from "./TableInfoFooter.module.css";

function getFooterContent(props) {
  const {
    variants: {
      variantName = "",
      table: {
        data: footerTableData = []
      } = {},
      buttons: {
        cancelBtnData: {
          text: cancelText,
          cb: cancelCallback = () => {}
        } = {},
        actionBtnData: {
          text: actionText,
          cb: actionCallback = () => {}
        } = {}
      } = {}
    } = {}
  } = props;

  switch (variantName) {
  case 'table':
    const footerRows = footerTableData.map(({ cols, id: rowId, extraRowClasses = [] }) => {
      const columns = cols.map(({ content, id, extraColClasses = [] }) => (
        <div className={`${classes.col} ${setClasses(extraColClasses, classes)}`} key={id}>
          <div className={classes.colContent} >
            <Typography
              variant="subtitle"
              size="l"
              className={classes.colText}
            >
              {content}
            </Typography>
          </div>
        </div>
      ));
      return (
        <div className={`${classes.row} ${setClasses(extraRowClasses, classes)}`} key={rowId}>
          {columns}
        </div>
      );
    });
    return (
      <div className={classes.table}>
        {footerRows}
      </div>
    );
  case 'buttons':
    return (
      <div className={`${classes.footerButtons} t-16 b-16 t-m-20 b-m-20`}>
        { cancelText && (
          <Button
            secondary
            onClick={cancelCallback}
            className="mr-16 mr-l-24 mr-xxl-32"
          >
            {cancelText}
          </Button>
        )}
        { actionText && (
          <Button onClick={actionCallback}>
            {actionText}
          </Button>
        )}
      </div>
    );
  default:
    return null;
  }
}
export function TableInfoFooter({ size, extraFooterClasses = [], ...rest }) {
  return (
    <div
      className={`${classes.footer} 
      ${classes[size]} 
      ${setClasses(extraFooterClasses, classes)}`}
    >
      {getFooterContent(rest)}
    </div>
  );
}

TableInfoFooter.propTypes = {
  size: PropTypes.oneOf([
    "small",
    "medium"
  ]),
  variants: PropTypes.shape({
    variantName: PropTypes.oneOf([
      "table",
      "buttons"
    ]),

    table: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          extraRowClasses: PropTypes.arrayOf(PropTypes.string),
          cols: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string,
              content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
              extraColClasses: PropTypes.arrayOf(PropTypes.string) // "boldText"
            })
          )
        })
      )
    }),

    buttons: PropTypes.shape({
      cancelBtnData: PropTypes.shape({
        text: PropTypes.string,
        cb: PropTypes.func
      }),
      actionBtnData: PropTypes.shape({
        text: PropTypes.string,
        cb: PropTypes.func
      })
    })
  }),
  /*
    "dn",
    "db-m",
    "dn-m"
  */
  extraFooterClasses: PropTypes.arrayOf(PropTypes.string)
};
