import { useMemo } from "react";

export function useExtraTextData(extraText) {
  const extraTextData = useMemo(() => {
    let etData = {
      data: extraText?.data
    };

    if (!extraText?.data) etData.data = extraText;
    return etData;
  }, [extraText]);

  return extraTextData;
}
