import { useMemo } from "react";

export function useNavData(nav) {
  const navData = useMemo(() => {
    let nData = {
      data: nav?.data
    };

    if (!nav?.data) nData.data = nav;
    return nData;
  }, [nav]);

  return navData;
}
