import React from "react";
import PropTypes from "prop-types";
import Modal from "components/Modal/Modal";
import TableInfo from "components/TableInfo/TableInfo";
import Typography from "components/Typography";
import { useScrollBarWidth } from "hooks/useScrollBarWidth";
import { TableInfoFooter } from "components/TableInfo/components/TableInfoFooter";
import { TableInfoNotes } from "components/TableInfo/components/TableInfoNotes";
import { TableInfoExtraText } from "components/TableInfo/components/TableInfoExtraText";
import { TableInfoBanner } from "components/TableInfo/components/TableInfoBanner";
import { useExtraTextData } from "components/TableInfo/hooks/useExtraTextData";
import { useNotesData } from "components/TableInfo/hooks/useNotesData";
import { ExtraTextDataType, NotesDataType, NavDataType, TableDataType }from "components/TableInfo/PropTypes";
import { setClasses } from "components/TableInfo/utils";
import classes from "./TableInfoModal.module.css";

const TableInfoModal = (props) => {
  const {
    show,
    extraContentClasses = [],
    title: {
      size: titleSize,
      text: titleText
    } = {},
    size = 'small',
    notes,
    extraText,
    bannerContent,
    onClose,
    footer,
    footer: {
      size: footerSize
    } = {},
    ...rest
  } = props;

  const extraTData = useExtraTextData(extraText);
  const {
    data: extraTextData = []
  } = extraTData;

  const nData = useNotesData(notes);
  const {
    data: notesData = []
  } = nData;

  const mdlRef = useScrollBarWidth();
  return (
    <Modal
      show={show}
      modalWrapClassnames={classes.modalContainer}
      modalClassnames={`${classes.modal} ${classes[size]}`}
      modalCrossButtonClassname={classes.crossButton}
      onClose={onClose}
      mdlRef={mdlRef}
    >
      <div className={`${classes.content} ${setClasses(extraContentClasses, classes)} ${classes[`footer-${footerSize}`]}`}>

        {(bannerContent !== null || titleText?.length !== 0) && (
          <header className={classes.modalHeader}>
            { titleText?.length !== 0 && (
              <Typography
                variant='headline'
                size='s'
                weight='bold'
                className={`${classes.title} ${classes[titleSize]}`}
              >
                {titleText}
              </Typography>
            )}

            {bannerContent !== null && <TableInfoBanner content={bannerContent} />}
          </header>
        )}

        <main className={classes.modalMain}>
          <TableInfo {...rest} />
          <TableInfoNotes notesData={notesData} />
          <TableInfoExtraText extraTextData={extraTextData} />
        </main>


        {footer && (
          <footer className={`${classes.modalFooter} ${classes[footerSize]}`}>
            <TableInfoFooter { ...footer} />
          </footer>
        )}
      </div>
    </Modal>
  );

};

TableInfoModal.propTypes = {
  show: PropTypes.bool,
  bannerContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  nav: PropTypes.oneOfType([
    PropTypes.shape({
      data: NavDataType
    }),
    NavDataType
  ]),
  table: PropTypes.oneOfType([
    PropTypes.shape({
      /*
        "db",   // show row
        "dn",   // hide row
        "db-m", // show (via display: block) row at 768+ resolution
        "dn-m", // hide (via display: none) row at 768+ resolution
        "db-l", // show (via display: block) row at 1024+ resolution
        "dn-l"  // hide (via display: none) row at 1024+ resolution
        "db-xl", // show (via display: block) row at 1440+ resolution
        "dn-xl"  // hide (via display: none) row at 1440+ resolution
        "db-xxl", // show (via display: block) row at 1680+ resolution
        "dn-xxl"  // hide (via display: none) row at 1680+ resolution
      */
      extraTableContainerClasses: PropTypes.arrayOf(PropTypes.string),
      extraTableContainerCSSModuleClasses: PropTypes.string,
      variant: PropTypes.oneOf([
        "default",
        "narrow"
      ]),
      data: TableDataType
    }),
    TableDataType
  ]),
  title: PropTypes.shape({
    size: PropTypes.oneOf([
      "small",
      "medium",
      "large"
    ]),
    text: PropTypes.string
  }),
  size: PropTypes.oneOf([
    "small",
    "medium"
  ]),
  notes: PropTypes.oneOfType([
    PropTypes.shape({
      data: NotesDataType
    }),
    NotesDataType
  ]),
  extraText: PropTypes.oneOfType([
    PropTypes.shape({
      data: ExtraTextDataType
    }),
    ExtraTextDataType
  ]),
  footer: PropTypes.shape({
    size: PropTypes.oneOf([
      "small",
      "medium"
    ]),
    variants: PropTypes.shape({
      variantName: PropTypes.oneOf([
        "buttons",
        "table"
      ]),

      table: PropTypes.shape({
        data: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string,
            extraRowClasses: PropTypes.arrayOf(PropTypes.string),
            cols: PropTypes.arrayOf(
              PropTypes.shape({
                id: PropTypes.string,
                content: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
                // "boldText"
                extraColClasses: PropTypes.arrayOf(PropTypes.string)
              })
            )
          })
        )
      }),

      buttons: PropTypes.shape({
        cancelBtnData: PropTypes.shape({
          text: PropTypes.string,
          cb: PropTypes.func
        }),
        actionBtnData: PropTypes.shape({
          text: PropTypes.string,
          cb: PropTypes.func
        })
      })
    })
  }),
  onClose: PropTypes.func
};

export default TableInfoModal;
