import React, { useRef } from "react";
import PropTypes from "prop-types";
import Typography from "components/Typography";
import chevron from "assets/images/chevron-down.svg";
import { useSubRows }from "components/TableInfo/hooks/useSubRows";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";
import { setClasses }from "components/TableInfo/utils";
import classnames from "classnames";
import classes from "../TableInfo.module.css";

function getSizeData(size) {
  switch (size) {
  case 'small':
    return {
      className: `${classes.colText}`,
      variant: 'body',
      size: 's'
    };
  case 'large':
    return {
      className: `${classes.colText}`,
      variant: 'subtitle',
      size: 'l',
      weight: 'bold'
    };
  case 'xLarge':
    return {
      className: `${classes.colText} ${classes.xLargeText}`,
      variant: 'subtitle',
      size: 'l',
      weight: 'bold'
    };
  case 'xLargeNormal':
    return {
      className: `${classes.colText} ${classes.xLargeText} ${classes.textNormal}`,
      variant: 'subtitle',
      size: 'l'
    };
  default:
    return {
      className: `${classes.colText}`,
      variant: 'body',
      size: 'l'
    };
  }
}

function getColspan(cs, xxSmDevice, smDevice) {
  if (!cs) return null;

  if (typeof cs === 'number') return cs;

  const { xxSmallDevice, smallDevice } = cs;

  if (xxSmDevice && xxSmallDevice) {
    return xxSmallDevice;
  } else if (smDevice && smallDevice) {
    return smallDevice;
  }

  return null;
}

const Row = (props) => {
  const {
    id: rowId,
    extraRowClasses = [],
    textSize,
    cols,
    hasSubRows,
    toogleShowSubRows = () => {},
    hiddenSubRows
  } = props;
  const xxSmallDevice = useMatchMedia({ maxWidth: MQ_BP.small });
  const smallDevice = useMatchMedia({ minWidth: MQ_BP.small });

  const columns = cols.map((
    {
      content = '',
      extraContent,
      id,
      extraColClasses = [],
      colspan
    },
    colIndex
  ) => {
    const isRegularContent = !hasSubRows || (hasSubRows && colIndex !== 0);
    const colSpan = getColspan(colspan, xxSmallDevice, smallDevice);
    return (
      <td
        key={id}
        colSpan={colSpan}
        className={`${classes.col} ${setClasses(extraColClasses, classes)}`}
      >
        <div className={classes.colInner}>
          <div className={classes.colContent}>
            {extraContent
              ? extraContent
              : (
                  <Typography className={classes.colText} {...getSizeData(textSize)}>
                    {isRegularContent && content}
                    {!isRegularContent && (
                      <span
                        className={classes.subRowsContent}
                        onClick={toogleShowSubRows}
                      >
                        {content}
                        <img
                          className={classnames(
                            classes.subRowsContentIcon,
                            hiddenSubRows && classes.hiddenSubRows
                          )}
                          alt=""
                          src={chevron}
                        />
                      </span>
                    )}
                  </Typography>
                )}

          </div>
        </div>
      </td>
    );
  });

  return (
    <tr
      key={rowId}
      className={`${classes.row} ${setClasses(extraRowClasses, classes)}`}
    >
      {columns}
    </tr>
  );
};
const TableInfoRows = ({ subRows = [], ...rest }) => {
  const subRowRef = useRef(null);
  const sRows = subRows.map((data) => <Row key={data.id} {...data} />);
  const hasSubRows = subRows.length !== 0;
  const hasRowCols = rest?.cols?.length;
  const [toogleShowSubRows, hiddenSubRows] = useSubRows(subRowRef);

  if (!hasRowCols) return null;

  return (
    <>
      <tbody>
        <Row
          {...rest}
          hasSubRows={hasSubRows}
          toogleShowSubRows={toogleShowSubRows}
          hiddenSubRows={hiddenSubRows}
        />
      </tbody>
      {hasSubRows && (
        <tbody
          className={classnames(
            classes.subRowsTable,
            hiddenSubRows && classes.hiddenSubRows
          )}
          ref={subRowRef}
        >
          {sRows}
        </tbody>
      )}
    </>
  );
};

TableInfoRows.propTypes = {
  id: PropTypes.string,
  extraRowClasses: PropTypes.array,
  textSize: PropTypes.string,
  cols: PropTypes.array,
  subRows: PropTypes.array
};

export default TableInfoRows;
