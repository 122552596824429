import { useMemo } from "react";

export function useNotesData(notes) {
  const notesData = useMemo(() => {
    let nData = {
      data: notes?.data
    };

    if (!notes?.data) nData.data = notes;
    return nData;
  }, [notes]);

  return notesData;
}
