import React from "react";
import PropTypes from "prop-types";
import TableInfoNavRows from "components/TableInfo/components/TableInfoNavRows";
import TableInfoRows from "components/TableInfo/components/TableInfoRows";
import { useUpdateTableData }from "components/TableInfo/hooks/useUpdateTableData";
import { useTableData }from "components/TableInfo/hooks/useTableData";
import { useNavData }from "components/TableInfo/hooks/useNavData";
import { NavDataType, TableDataType }from "components/TableInfo/PropTypes";
import { setClasses } from "components/TableInfo/utils";
import classes from "./TableInfo.module.css";

const TableInfo = (props) => {
  const {
    nav,
    table
  } = props;

  const tData = useTableData(table);
  const nData = useNavData(nav);
  const {
    extraTableContainerClasses = [],
    extraTableContainerCSSModuleClasses = [],
    variant = 'default',
    data: tableData = []
  } = tData;

  const {
    data: navData = {}
  } = nData;

  const [
    newTableData,
    updateTableData
  ] = useUpdateTableData(tableData, navData);

  if (!tableData?.length) return null;

  const tableRows = newTableData.map((data) => (
    <TableInfoRows {...data} key={data.id} />
  ));
  const hasNavCols = navData?.cols;

  return (
    <div
      className={`
        ${classes.tableContainer}
        ${extraTableContainerCSSModuleClasses} 
        ${setClasses([...extraTableContainerClasses, variant], classes)}
      `}
    >
      <table className={classes.table}>
        <tbody className={classes.displayContents}>
          { hasNavCols && (
            <tr className={classes.displayContents}>
              <td className={classes.displayContents}>
                <table className={`${classes.subTable} ${classes.navTable}`}>
                  <tbody className={classes.displayContents}>
                    <TableInfoNavRows
                      navData={navData}
                      updateTableData={updateTableData}
                    />
                  </tbody>
                </table>
              </td>
            </tr>
          )}
          <tr className={classes.displayContents}>
            <td className={classes.displayContents}>
              <table className={classes.subTable}>
                {tableRows}
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

TableInfo.propTypes = {
  nav: PropTypes.oneOfType([
    PropTypes.shape({
      data: NavDataType
    }),
    NavDataType
  ]),
  table: PropTypes.oneOfType([
    PropTypes.shape({
      /*
        "db",   // show row
        "dn",   // hide row
        "db-m", // show (via display: block) row at 768+ resolution
        "dn-m", // hide (via display: none) row at 768+ resolution
        "db-l", // show (via display: block) row at 1024+ resolution
        "dn-l"  // hide (via display: none) row at 1024+ resolution
        "db-xl", // show (via display: block) row at 1440+ resolution
        "dn-xl"  // hide (via display: none) row at 1440+ resolution
        "db-xxl", // show (via display: block) row at 1680+ resolution
        "dn-xxl"  // hide (via display: none) row at 1680+ resolution
      */
      extraTableContainerClasses: PropTypes.arrayOf(PropTypes.string),
      extraTableContainerCSSModuleClasses: PropTypes.string,
      variant: PropTypes.oneOf([
        "default",
        "narrow"
      ]),
      data: TableDataType
    }),
    TableDataType
  ])
};

export default TableInfo;
