import { useCallback, useState, useEffect } from "react";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";

function updateRowsData(data, index) {
  const newData = [...data].map(data => {
    const startIndex = index + 1;
    const cols = data.cols;
    const col = cols.slice(startIndex, startIndex + 1);
    const newCols = [cols[0], ...col];

    const subRows = data?.subRows;

    if (subRows?.length) {
      const newSubRowsData = updateRowsData(subRows, index);
      return { ...data, cols: newCols, subRows: newSubRowsData };
    }
    return { ...data, cols: newCols };
  });

  return newData;
}

function updateTab(data, tabIndex, updateState) {
  const newData = updateRowsData(data, tabIndex);
  updateState(newData);
}

export function useUpdateTableData(data, navData) {
  const [state, setState] = useState(data);
  const [activeTab, setActiveTab] = useState(0);
  const smallDevice = useMatchMedia({ maxWidth: MQ_BP.small });

  const updateTableData = useCallback((tabIndex, ...rest) => {
    if (navData?.cols) {
      const navLinkClick = navData?.cb;
      updateTab(data, tabIndex, setState);
      setActiveTab(tabIndex);
      navLinkClick && navLinkClick(tabIndex, ...rest);
    }
  }, [data, navData, setState]);

  useEffect(() => {
    if (navData?.cols) {
      updateTab(data, activeTab, setState);
    }
  }, [activeTab, data, navData?.cols, setState, smallDevice]);

  useEffect(() => {
    updateTab(data, activeTab, setState);
  }, [activeTab, data]);

  if (!smallDevice) return [data, updateTableData];
  return [state, updateTableData];
}
