import Typography from "components/Typography";
import { ExtraTextDataType }from "components/TableInfo/PropTypes";
import { setClasses } from "components/TableInfo/utils";
import classes from "./TableInfoExtraText.module.css";
export function TableInfoExtraText({ extraTextData = [] }) {
  if (!extraTextData?.length) return null;

  const extraTexts = extraTextData.map(({ content, id, extraRowClasses = [] }) => (
    <div
      className={`
        ${classes.extraTextRow} 
        ${setClasses(extraRowClasses, classes)}
      `}
      key={id}
    >
      <Typography
        variant="body"
        size="s"
        className={classes.extraTextContent}
      >
        {content}
      </Typography>
    </div>
  ));
  return (
    <div className={`${classes.extraText} t-8 t-m-12`}>
      {extraTexts}
    </div>
  );
}

TableInfoExtraText.propTypes = {
  extraTextData: ExtraTextDataType
};
