import Typography from "components/Typography";
import { BannerDataType }from "components/TableInfo/PropTypes";
import { setClasses } from "components/TableInfo/utils";
import classes from "./TableInfoExtraText.module.css";

export function TableInfoBanner(
  {
    content,
    extraContainerClasses = [],
    extraContentClasses = []
  }) {

  if (!content) {
    return null;
  }

  return (
    <div
      className={`
        ${classes.container} 
        ${setClasses(extraContainerClasses, classes)}
    `}>
      <div
        className={`
        ${classes.content} 
        ${setClasses(extraContentClasses, classes)}
    `}>
        {content}
      </div>
    </div>
  );
}

TableInfoBanner.propTypes = BannerDataType;
