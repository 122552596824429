import { useCallback, useState } from "react";

export function useSubRows(ref) {
  const [hidden, setHidden] = useState(true);
  const toogleShowSubRows = useCallback(() => {
    if (ref.current) {
      setHidden((s) => !s);
    }
  }, [ref, setHidden]);

  return [toogleShowSubRows, hidden];
}
