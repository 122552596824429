import React from "react";
import { changePrice } from "utils/changePrice";
import FormattedPrice from "components/FormattedPrice/FormattedPrice";
import { MQ_BP, useMatchMedia } from "components/MatchMedia";

export const useDetailsData = (price) => {

  const isSmallDevice = useMatchMedia({ maxWidth: MQ_BP.small });

  if (!price) return [null, null];

  const duration = price.duration;

  const tableData = [];
  const footerData = [];

  if (price.rental_fee?.cents_total) {
    const perDay = changePrice(price.rental_fee, (x) => x / duration);
    tableData.push({
      id: 'row-1',
      extraRowClasses: ['borderTopSolid'],
      cols: [
        {
          id: "col-1",
          content: (
            <span>Rental fee <span className="dib no-wrap">(${perDay.user_friendly} x {duration} nights)</span></span>
          )
        },
        {
          id: "col-2",
          content: (<FormattedPrice value={price.rental_fee} isBold />)
        }
      ]
    });
  }

  if (price.delivery_fee?.cents_total) {
    tableData.push({
      id: 'row-4',
      cols: [
        {
          id: "col-1",
          content: 'Delivery fee'
        },
        {
          id: "col-2",
          content: (<FormattedPrice value={price.delivery_fee} isBold />)
        }
      ]
    });
  }

  if (price.cleaning_fee?.cents_total) {
    tableData.push({
      id: 'row-2',
      cols: [
        {
          id: "col-1",
          content: 'Cleaning fee'
        },
        {
          id: "col-2",
          content: (<FormattedPrice value={price.cleaning_fee} isBold />)
        }
      ]
    });
  }

  if (price.insurance_fee?.cents_total) {
    tableData.push({
      id: 'row-3',
      cols: [
        {
          id: "col-1",
          content: 'Insurance fee'
        },
        {
          id: "col-2",
          content: (<FormattedPrice value={price.insurance_fee} isBold />)
        }
      ]
    });
  }

  if (price.disposal_fee?.cents_total) {
    tableData.push({
      id: 'row-3',
      cols: [
        {
          id: "col-1",
          content: 'Disposal fee'
        },
        {
          id: "col-2",
          content: (<FormattedPrice value={price.disposal_fee} isBold />)
        }
      ]
    });
  }

  if (price.add_ons_fee?.cents_total) {
    let addOnsContent = 'Add-ons';

    tableData.push({
      id: 'row-5',
      cols: [
        {
          id: "col-1",
          content: addOnsContent
        },
        {
          id: "col-2",
          content: (<FormattedPrice value={price.add_ons_fee} isBold />)
        }
      ]
    });
  }

  if (price.mileage_overage_fee?.cents_total) {
    tableData.push({
      id: 'row-6',
      cols: [
        {
          id: "col-1",
          content: `Mileage overage (${price.mileage_overage} mi)`
        },
        {
          id: "col-2",
          content: (<FormattedPrice value={price.mileage_overage_fee} isBold />)
        }
      ]
    });
  }

  if (price.generator_hours_overage_fee?.cents_total) {
    tableData.push({
      id: 'row-7',
      extraRowClasses: ['borderTopNone'],
      cols: [
        {
          id: "col-1",
          content: `Generator overage (${price.generator_hours_overage} h)`
        },
        {
          id: "col-2",
          content: (<FormattedPrice value={price.generator_hours_overage_fee} isBold />)
        }
      ]
    });
  }

  if (price.owner_share) {
    tableData.push({
      id: 'row-8',
      extraRowClasses: ['borderTopSolid'],
      cols: [
        {
          id: "col-1",
          content: 'Your share'
        },
        {
          extraColClasses: ['boldText'],
          id: "col-2",
          content: `${price.owner_share}`
        }
      ]
    });
  }

  if (price.owner_earns && !isSmallDevice) {
    let extraRowClasses = ['borderTopNone'];

    tableData.push({
      id: 'row-9',
      extraRowClasses,
      textSize: 'xLarge',
      cols: [
        {
          id: "col-1",
          content: "You earn"
        },
        {
          id: "col-2",
          content: (<FormattedPrice value={price.owner_earns} isBold />)
        }
      ]
    });
  }

  /* Footer for Owner */
  if (price.owner_earns && isSmallDevice) {
    footerData.push({
      id: 'row-1',
      cols: [
        {
          id: "col-1",
          extraColClasses: ['boldText'],
          content: "You earn"
        },
        {
          id: "col-2",
          content: (<FormattedPrice value={price.owner_earns} isBold />)
        }
      ]
    });
  }
  return [
    tableData,
    footerData
  ];
};
