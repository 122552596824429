import { formatCents } from "utils/convertCents";

export const changePrice = (value, changer) => {
  if (!value) {
    return null;
  }

  return formatPrice(changer(value.cents_total));
};

export const formatPrice = (centsTotal) => {
  const dollarsFloat = centsTotal / 100;
  const dollars_as_part = Math.floor(dollarsFloat);
  const cents_as_part = Math.round((dollarsFloat - dollars_as_part) * 100);

  return {
    cents_total: centsTotal,
    dollars_as_part,
    cents_as_part,
    user_friendly: `${dollars_as_part}.${formatCents(cents_as_part)}`
  };
};
